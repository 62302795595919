<template>
  <section class="panel-section">
    <div class="back-btn" @click="back">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>
    <el-form
      label-position="left"
      :model="formModel"
      status-icon
      ref="formModel"
      label-width="140px"
    >
      <div class="title">工作台账号实名信息</div>
      <el-form-item label="注册手机号码">
        <el-input
          type="input"
          v-model="formModel.phone"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input
          type="input"
          v-model="formModel.realName"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="证件类型">
        <el-select class="com-width" v-model="formModel.idCardType" disabled>
          <el-option label="居民身份证" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证件编号">
        <el-input
          type="input"
          v-model="formModel.idCardNo"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <div class="title">认证信息</div>
      <el-form-item label="认证提交时间">
        <el-date-picker
          v-model="formModel.auditCommitTime"
          type="datetime"
          class="com-width"
          disabled>
        </el-date-picker>
      </el-form-item>
      <el-form-item label="云盾审核时间">
        <el-date-picker
          v-model="formModel.auditTime"
          type="datetime"
          class="com-width"
          disabled>
        </el-date-picker>
      </el-form-item>
      <el-form-item label="云盾审核结果">
        <el-select class="com-width" v-model="formModel.auditStatus" disabled>
          <el-option
            v-for="(item, i) in auditConditionList.slice(1)"
            :key="i"
            :label="item.label"
            :value="Number(item.value)"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
import { auditConditionList, indentifyTypeConditionList } from "@/util/constant.js";
import API_ACCOUNT from "@/api/api_account";
export default {
  data() {
    return {
      indentifyTypeConditionList,
      auditConditionList,
      id: undefined,
      formModel: {
        phone: undefined,
        realName: undefined,
        idCardType: 1,
        idCardNo: undefined,
        auditCommitTime: undefined,
        auditTime: undefined,
        auditStatus: undefined
      },
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    resetForm() {
      this.formModel = {
        phone: undefined,
        realName: undefined,
        idCardType: 1,
        idCardNo: undefined,
        auditCommitTime: undefined,
        auditTime: undefined,
        auditStatus: undefined
      };
    },
    getRealNameInfo() {
      API_ACCOUNT.realNameInfo(this.id).then((res = {}) => {
        const { data: { data = {}, code, msg = "" } = {} } = res;
        if (code == 200 && data) {
          this.formModel = data;
        } else {
          this.resetForm();
        }
      });
    },
  },
  activated() {
    this.id = this.$route.query.id;
    this.resetForm();
    if (this.id) {
      this.getRealNameInfo();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_basePage.scss";

.panel-section {
  padding: 40px 48px;
  .title {
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303133;
    margin: 24px 0;
  }
}

.com-width {
  width: 400px !important;
  min-width: 400px !important;
}
/deep/ .el-alert {
  padding: 0 16px;
}
</style>